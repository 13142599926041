<template>
	<div data-component="game-engine-buttons">
		<btn
			text="Open in standalone mode"
			@click.native="openInMode('standalone')"
		/>
		<btn
			text="Open in student mode"
			@click.native="openInMode('student')"
		/>
		<btn
			text="Open in teacher mode"
			@click.native="openInMode('teacher')"
		/>
	</div>
</template>

<script>

	import Btn from '@/components/ui/Btn';
	import api from '@/services/api';
	import { openExternalLink } from '@/helpers';

	export default {
		components: {
			Btn
		},
		props: {
			dealId: {
				type: String,
				required: true
			}
		},
		data () {
			return {
				loading: false
			};
		},
		computed: {
		},
		methods: {
			async getRoomToken (mode) {
				if (this.loading) {
					return;
				}
				this.loading = true;
				const deals = [this.dealId];

				const { roomToken } = await api.deals.createRoom({
					mode,
					dealIds: deals,
					exitRedirectUrl: window.location.href,
					dealRecordingEnabled: mode === 'teacher' ?  deals : [],
					metadata: null // Use it for student mode
				});
				this.loading = false;
				return roomToken;
			},
			openGameEngine (roomToken) {
				const path = process.env.VUE_APP_GAME_ENGINE_URL || this.$route.query.experimentalGameEngineUrl;
				if (path) {
					openExternalLink(path, roomToken ? { roomToken } : {});
				}
			},
			async openInMode (mode) {
				const roomToken = await this.getRoomToken(mode);

				this.openGameEngine(roomToken);
			}
		}
	};

</script>

<style lang="scss" scoped>

[data-component='game-engine-buttons'] {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	[data-component='btn'] {
		margin-top: rem(12);
	}
}

</style>
